import React from "react";
import { graphql } from "gatsby";
import { ImageDataLike } from "gatsby-plugin-image";

import MainLayout from "../../layouts/main-layout";
import { IBanner } from "../../models/banner.model";
import { IPostBrief } from "../../models/post.model";
import { IAuthor } from "../../models/author.model";
import { IInstagramPost } from "../../models/instagram-post.model";
import { ISliderProduct } from "../../models/slider-product.model";
import QueryEdgeNode from "../../models/query-edge-node.model";
import getNodes from "../../utils/get-nodes";
import getPostsWithAuthor from "../../utils/get-posts-with-author";
import { ISite } from "../../models/site.model";
import { IPageBase } from "../../models/page-base.model";
import usePageMetadata from "../../hooks/use-page-metadata";

import MainSection from "../organisms/sections/common/main-section";

import AboutSection from "../organisms/sections/home/about-section";
import BenefitsSection from "../organisms/sections/home/benefits-section";
import HowToSection from "../organisms/sections/home/how-to-section";
import BannerSection from "../organisms/sections/banner-section";
import InstagramSection from "../organisms/sections/home/instagram-section";
import PostsSection from "../organisms/sections/posts-section";
import SubsectionsAdvices from "../organisms/sections/home/subsections-advices";
import OpinionSlider from "../organisms/sections/home/opinion-slider";
import ProductsSlider from "../organisms/sections/home/products-slider";
import HomeClothesSection from "../organisms/sections/home/home-clothes-section";
import HomeAboutSection from "../organisms/sections/home/home-about-section";
import HomeSurveySection from "../organisms/sections/home/home-survey-section";


import AboutBackground from '../../assets/images/svg/Fashion-fairy-logo.svg';
import TwoBoxSection from "../organisms/sections/common/two-box-section";
import BackgroundBoxSection from "../organisms/sections/common/background-box-section";


import {
    index,
    mainSection,
    aboutContinueSectionLeftWrapper,
    quoteSection,
    lastSection
} from './index.module.scss';
import BackgroundTextSection from "../organisms/sections/common/background-text-section";
import HomeSubpageSection from "../organisms/sections/home/home-subpage-section";
import HomeSubpageSection2 from "../organisms/sections/home/home-subpage-section2";

interface IIndexProps extends IPageBase {
    readonly data: {
        bannersCollection: {
            banners: IBanner[];
        } | null;
        promotedPosts: QueryEdgeNode<IPostBrief>;
        authors: QueryEdgeNode<IAuthor>;
        allInstagram: QueryEdgeNode<IInstagramPost>;
        site: ISite;
        allSliderProduct: QueryEdgeNode<ISliderProduct>
    } & { [key: string]: ImageDataLike };
}

const IndexPage: React.FC<IIndexProps> = ({ data, pageContext }) => {
    const banners = data.bannersCollection?.banners || [];
    const promotedPosts = getNodes(data.promotedPosts);
    const authors = getNodes(data.authors);
    const instagramPosts = getNodes(data.allInstagram);
    const products = getNodes(data.allSliderProduct);
    const postsWithAuthor = getPostsWithAuthor(promotedPosts, authors);

    const areBanners = banners.length > 0;

    const { defaultStructuredData } = usePageMetadata(pageContext, data.site);

    return (
        <MainLayout
            className={index}
            headerColor={"white"}
            SEOProps={{ structuredData: defaultStructuredData }}
        >
            {/*areBanners && <BannerSection banners={banners} />*/}
            {/*<AboutSection withTopPadding={areBanners} />*/}
            <MainSection withTopPadding={areBanners}
                showButton={true}
                titleText={<div>Virtual Stylist</div>}
                text1="FASHION FAIRY TO INNOWACYJNY PROJEKT, KTÓRY ŁĄCZY BLOG MODOWY Z QUIZEM STYLISTYCZNYM. ZAMIAST GODZINAMI SZUKAĆ STYLIZACJI W MILIONACH SKLEPÓW, WYPEŁNIJ QUIZ I OTRZYMAJ PROPOZYCJE DOPASOWANE DO TWOICH PREFERENCJI. ZNAJDZIESZ TU ZBIÓR UBRAŃ Z RÓŻNYCH SKLEPÓW I OSZCZĘDZISZ CZAS I OTRZYMUJĄC IDEALNE UBRANIA NA KAŻDĄ OKAZJĘ."
                text2="KLIKNIJ „WYSTYLIZUJ MNIE”, ZAUFAJ WRÓŻCE STYLISTCE I CIESZ SIĘ DARMOWĄ, SPERSONALIZOWANĄ MODĄ JUŻ TERAZ"
                image={AboutBackground}
                className={mainSection}
            />
 
            <HomeAboutSection withTopPadding={areBanners} />
            <HomeClothesSection withTopPadding={areBanners} />
            <BackgroundBoxSection withTopPadding={areBanners}
                className={quoteSection}
                quoteText1={"\"Twoja moda, Twoja historia. Opowiedz ją z Fashion Fairy\""}
                quoteSubtext1={"Fashion Fairy"} />
            <TwoBoxSection withTopPadding={areBanners}
                classNameLeftWrapper={aboutContinueSectionLeftWrapper}
                title={"Niech Twoje modowe marzenia staną się rzeczywistością z Fashion Fairy!"}
                text={<div>
                    <div>Przeglądanie tysięcy zdjęć ubrań online może być przytłaczające i czasochłonne. Szukasz czegoś idealnego na specjalną okazję lub na co dzień, a zamiast tego tracisz godziny, a nawet dni, przeglądając kolejne strony. Frustracja narasta, a znalezienie nowej stylizacji wydaje się niemożliwe.
                        I właśnie dlatego powstał serwis Fashion Fairy!
                    </div>
                    <div>Zaufaj naszej Wróżce Stylistce, wypełnij quiz i ciesz się spersonalizowanymi propozycjami, które oszczędzą Twój czas i sprawią, że poczujesz się wyjątkowo.
                    </div>
                    <div>Kliknij „Wystylizuj mnie” i odkryj swój idealny styl już teraz!</div> 
                </div>}
                quoteText2={"\"Moda to sztuka, a Ty jesteś płótnem.\""}
                quoteSubtext2={"Anonimowy"} />
            <HomeSubpageSection withTopPadding={areBanners} />
            <HomeSubpageSection2 withTopPadding={areBanners} />
            <HomeSurveySection withTopPadding={areBanners} />
            <BackgroundTextSection withTopPadding={areBanners} className={lastSection} />
            {/*STYLIZACJE PROPONOWANE*/}
            {/*
                products && products.length > 0 &&
                <ProductsSlider products={products} />*/
            }
            {/*STYLIZACJE PROPONOWANE*/}
            {/*<BenefitsSection />
            <HowToSection />
            <SubsectionsAdvices data={data} pageContext={pageContext} />
            <OpinionSlider />*/}
            {/*POSTY */}
            {/*postsWithAuthor.length > 0 && (
                <PostsSection
                    posts={postsWithAuthor}
                    authors={authors}
                    titleKey="posts-section.title"
                    subtitleKey="common.blog"
                />
            )*/}
            {/*POSTY */}
            {instagramPosts.length > 0 && <InstagramSection posts={instagramPosts} />}
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!, $placeId: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        bannersCollection(placeId: { eq: $placeId }) {
            ...bannersCollectionFields
        }

        promotedPosts: allPost(sort: { fields: publishedAt, order: DESC }, limit: 4) {
            edges {
                node {
                    ...postBriefFields
                }
            }
        }

        authors: allAuthor {
            edges {
                node {
                    ...authorFields
                }
            }
        }
        
        allSliderProduct {
            edges {
                node {
                    productId
                    categoryGroup
                    category
                    brandName
                    url
                    affiliateUrl
                    imgUrl
                }
            }
        }

        allInstagram {
            edges {
                node {
                    ...instagramFields
                }
            }
        }

        coverImg: file(relativePath: { eq: "subsection-1.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        figures: file(relativePath: { eq: "subsection-2.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        mainImg: file(relativePath: { eq: "subsection-3.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default IndexPage;

// extracted by mini-css-extract-plugin
export var backgroundImage = "two-box-section-module--background-image--U66-W";
export var button = "two-box-section-module--button--DwOWd";
export var buttonNew = "two-box-section-module--button-new--vM4S8";
export var hidden = "two-box-section-module--hidden--QV9cS";
export var leftWrapper = "two-box-section-module--left-wrapper--i3C0i";
export var leftWrapperFirstQ1 = "two-box-section-module--left-wrapper-first-q1--MMBuP";
export var leftWrapperFirstQ2 = "two-box-section-module--left-wrapper-first-q2--ti7Id";
export var main = "two-box-section-module--main--mUPSZ";
export var mainReverse = "two-box-section-module--main-reverse--FDrNK";
export var rightWrapper = "two-box-section-module--right-wrapper--H9cQq";
export var rightWrapperContent = "two-box-section-module--right-wrapper-content--jUjPB";
export var rightWrapperContentReverse = "two-box-section-module--right-wrapper-content-reverse--w9wh2";
export var rightWrapperContentTitle = "two-box-section-module--right-wrapper-content-title--kZf4C";
export var rightWrapperReverse = "two-box-section-module--right-wrapper-reverse--ZPPpN";
export var rightWrapperTitle = "two-box-section-module--right-wrapper-title--FK8m0";
export var rightWrapperTitleReverse = "two-box-section-module--right-wrapper-title-reverse--rdwwg";
export var subtitle = "two-box-section-module--subtitle--G6XjB";
export var titleContainer = "two-box-section-module--title-container--2Pb0-";
export var topPadding = "two-box-section-module--top-padding--mBhWx";
export var visible = "two-box-section-module--visible--+vh0P";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    contentLeft,
    contentFirst,
    contentTitle,
    secondWrapper,
    secondWrapperTitle,
    buttonNew,
    quoteFirst,
    contentFirstMobile,
    quoteTwo,
} from './home-subpage-section2.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeSubpageSection2Props {
    withTopPadding?: boolean;
}

export default function HomeSubpageSection2({ withTopPadding }: HomeSubpageSection2Props) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={secondWrapperTitle}>

                </div>
                <div className={contentLeft}>
                    <Link to="/okresl-figure">
                        <h2>Określ swoją figurę i dowiedz się co Ci pasuje</h2>
                        <div><br/>Odkryj, co podkreśli Twoje atuty i ukryje mankamenty. Poznaj swoją sylwetkę i stwórz stylizacje idealne.</div>
                    </Link>
                </div>
            </div>

            <div className={secondWrapper}>
                <div className={secondWrapperTitle}>

                </div>
                <div className={content}>
                    <Link to="/typ-urody">
                        <h2>Znajdź najlepszy kolor dla siebie</h2>
                        <div>Czy Twój ulubiony kolor naprawdę Cię upiększa?  Poznaj swój typ urody i odkryj barwy, które podkreślą Twoje naturalne piękno.</div>
                    </Link>
                </div>
            </div>
        </SectionWrapper>
    );
}

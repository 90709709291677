// extracted by mini-css-extract-plugin
export var backgroundImage = "home-subpage-section2-module--background-image--mLFie";
export var button = "home-subpage-section2-module--button--PFPTi";
export var buttonNew = "home-subpage-section2-module--button-new--FN8uV";
export var content = "home-subpage-section2-module--content--ZIyo0";
export var contentFirst = "home-subpage-section2-module--content-first--l9F3T";
export var contentFirstMobile = "home-subpage-section2-module--content-first-mobile--WZwJ1";
export var contentLeft = "home-subpage-section2-module--content-left--V3+uR";
export var contentTitle = "home-subpage-section2-module--content-title--X5GZb";
export var contentWrapper = "home-subpage-section2-module--content-wrapper--SQIlx";
export var hidden = "home-subpage-section2-module--hidden--i20hJ";
export var main = "home-subpage-section2-module--main--M4fXP";
export var quoteFirst = "home-subpage-section2-module--quoteFirst--kLXa7";
export var quoteTwo = "home-subpage-section2-module--quoteTwo--MTR9Q";
export var secondWrapper = "home-subpage-section2-module--second-wrapper--VKUps";
export var secondWrapperTitle = "home-subpage-section2-module--second-wrapper-title--BT-4J";
export var subtitle = "home-subpage-section2-module--subtitle--iB3na";
export var titleContainer = "home-subpage-section2-module--title-container--O0Wmr";
export var topPadding = "home-subpage-section2-module--top-padding--bYB9-";
export var visible = "home-subpage-section2-module--visible--zwFGC";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    mainReverse,
    rightWrapperContent,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    leftWrapper,
    leftWrapperFirstQ1,
    leftWrapperFirstQ2,
    rightWrapperContentTitle,
    rightWrapper,
    rightWrapperTitle,
    rightWrapperReverse,
    rightWrapperTitleReverse,
    rightWrapperContentReverse,
    buttonNew,
} from './two-box-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface TwoBoxSectionProps {
    withTopPadding?: boolean;
    isReverse?: boolean;
    title?: string;
    text?: React.ReactNode;
    quoteText1?: string;
    quoteSubtext1?: string;
    quoteText2?: string;
    quoteSubtext2?: string;
    className?: string;
    classNameLeftWrapper?: string;
    classNameRightWrapper?: string;
}

export default function TwoBoxSection({ withTopPadding, isReverse, title, text, quoteText1, quoteSubtext1, quoteText2, quoteSubtext2, className, classNameLeftWrapper, classNameRightWrapper }: TwoBoxSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${className} ${isReverse ? mainReverse : ''} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={`${leftWrapper} ${classNameLeftWrapper}`}>
                {quoteText1 && <div className={leftWrapperFirstQ1}>
                    <Quote text={quoteText1} subtext={quoteSubtext1}></Quote>
                </div>}
                {quoteText2 && <div className={leftWrapperFirstQ2}>
                    <Quote text={quoteText2} subtext={quoteSubtext2}></Quote>
                </div>}
            </div>

            <div className={`${rightWrapper} ${classNameRightWrapper} ${isReverse ? rightWrapperReverse : ''}`}>
                <div className={`${rightWrapperContent} ${isReverse ? rightWrapperContentReverse : ''}`}>
                    <h2 className={`${rightWrapperContentTitle}`}>{title}</h2>
                    {text && text} 
                </div>
                {quoteText2 &&<div className={`${rightWrapperTitle} ${isReverse ? rightWrapperTitleReverse : ''}`}>
                    <Quote text={quoteText2} subtext={quoteSubtext2}></Quote>
                </div>}
            </div>
        </SectionWrapper>
    );
}

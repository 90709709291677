import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    secondWrapper,
    buttonNew,
} from './home-clothes-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeClothesSectionProps {
    withTopPadding?: boolean;
}

export default function HomeClothesSection({ withTopPadding }: HomeClothesSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark2'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={content}>
                    <h1>Czy dobrze się ubrałaś?</h1>
                    <h2>Z Fashion Fairy zawsze będziesz wiedziała, że TAK! Ten unikalny projekt został stworzony dla osób, które chcą:</h2>
                    <ul>
                        <li>Stworzyć lub dopracować swój niepowtarzalny styl bez wychodzenia z domu.</li>
                        <li>Mieć wszystkie specjalnie dobrane ubrania w jednym miejscu, bez przeszukiwania dziesiątek sklepów.</li>
                        <li>Nauczyć się świadomości dobrego stylu i podkreślić swoje atuty.</li>
                        <li>Poznać świat mody od podszewki i być na bieżąco z obecnymi trendami.</li>
                        <li>Uniknąć modowych faux pas i perfekcyjnie odczytywać dress code na każdą okazję.</li>
                    </ul>
                    <h2>
                        Z Fashion Fairy zyskujesz pewność siebie, oszczędzasz czas i zawsze wyglądasz perfekcyjnie!
                    </h2>
                </div>
            </div>
   
            <div className={secondWrapper}>
                <Quote text={"\"Zaufaj Wróżce Stylistce i odkryj swój unikalny styl.\""} subtext={"Fashion Fairy"}></Quote>
            </div>
        </SectionWrapper>
    );
}

// extracted by mini-css-extract-plugin
export var backgroundImage = "main-section-module--background-image--Yk1DT";
export var button = "main-section-module--button--KMsJs";
export var buttonNew = "main-section-module--button-new--pz29T";
export var content = "main-section-module--content--Z9PDL";
export var contentWrapper = "main-section-module--content-wrapper--8eIfp";
export var hidden = "main-section-module--hidden--bqRHh";
export var main = "main-section-module--main--7gOcg";
export var subtitle = "main-section-module--subtitle--ZxAMP";
export var title = "main-section-module--title--NYTks";
export var titleContainer = "main-section-module--title-container--GlRZl";
export var topPadding = "main-section-module--top-padding--TYj+D";
export var visible = "main-section-module--visible--fMpEM";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    buttonNew,
} from './main-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import ButtonWand from '../../../molecules/button-wand';

export interface MainSectionProps {
    withTopPadding?: boolean;
    showButton: boolean;
    titleText?: React.ReactNode;
    text1?: string;
    text2?: string;
    image?: React.ReactNode;
    className?: string
}

export default function MainSection({ withTopPadding, showButton, titleText, text1, text2, image: Image, className }: MainSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'main');
    console.log('className: ',className);
    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${className} ${withTopPadding ? topPadding : ''}`}
        >
            <Section
                ref={backgroundRef}
                className={`${backgroundImage} ${backgroundEntry?.isIntersecting ? visible : hidden
                    }`}
            >
                {Image && <Image />}
            </Section>
            <Section
                ref={contentRef}
                column={'narrow'}
                className={`${contentWrapper} ${contentEntry?.isIntersecting ? visible : hidden
                    }`}
                title={{
                    size: 'medium',
                    subtitle: '',
                    containerClassName: titleContainer,
                    className: title,
                    subtitleClassName: subtitle,
                    children: titleText,
                    align: 'center',
                    visible: contentEntry?.isIntersecting,
                }}
            >
                {text1 && <p className={content}>{text1}</p>}
                {text2 && <p className={content}>{text2}</p>}

                {showButton && <ButtonWand text={t(aboutTranslations('button'))}></ButtonWand>}

                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
            </Section>
        </SectionWrapper>
    );
}

import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    contentFirst,
    contentTitle,
    secondWrapper,
    secondWrapperTitle,
    buttonNew,
    quoteFirst,
    contentFirstMobile,
    quoteTwo,
} from './home-subpage-section.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface HomeSubpageSectionProps {
    withTopPadding?: boolean;
}

export default function HomeSubpageSection({ withTopPadding }: HomeSubpageSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={contentFirst}>
                    <Quote className={quoteFirst} text={"\"Właściwy strój to przejaw dobrych manier.\""} subtext={"Tom Ford"}></Quote>
                </div>
                <div className={contentFirstMobile}>
                    <Quote className={quoteFirst} text={"\"Właściwy strój to przejaw dobrych manier\""} subtext={"Tom Ford"}></Quote>
                </div>
            </div>

            <div className={secondWrapper}>
                <div className={secondWrapperTitle}>

                </div>
                <div className={content}>
                    <Link to="/jak-sie-mierzyc">
                        <h2>Jak prawidłowo się mierzyć?</h2>
                        <div>Kupujesz online? Dowiedz się, jak dobrze się zmierzyć, by zamówione ubrania leżały idealnie. <br/>Porady, które ułatwią zakupy bez wpadek!</div>
                    </Link>
                </div>
            </div>
        </SectionWrapper>
    );
}

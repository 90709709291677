// extracted by mini-css-extract-plugin
export var backgroundImage = "home-subpage-section-module--background-image--SxtmU";
export var button = "home-subpage-section-module--button--zvqD2";
export var buttonNew = "home-subpage-section-module--button-new--O2L3H";
export var content = "home-subpage-section-module--content--RrYTb";
export var contentFirst = "home-subpage-section-module--content-first--f4uJU";
export var contentFirstMobile = "home-subpage-section-module--content-first-mobile--+stbA";
export var contentTitle = "home-subpage-section-module--content-title--4Aimb";
export var contentWrapper = "home-subpage-section-module--content-wrapper--2N8cU";
export var hidden = "home-subpage-section-module--hidden--SJ0s0";
export var main = "home-subpage-section-module--main--O4I7m";
export var quoteFirst = "home-subpage-section-module--quoteFirst--0Qbv-";
export var quoteTwo = "home-subpage-section-module--quoteTwo--rdDt6";
export var secondWrapper = "home-subpage-section-module--second-wrapper--8-Hge";
export var secondWrapperTitle = "home-subpage-section-module--second-wrapper-title--KIdf1";
export var subtitle = "home-subpage-section-module--subtitle--PTikz";
export var titleContainer = "home-subpage-section-module--title-container--1bkbo";
export var topPadding = "home-subpage-section-module--top-padding--NmdFL";
export var visible = "home-subpage-section-module--visible--kkLII";
import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    topPadding,
    contentWrapper,
    image,
} from './background-text-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';

export interface BackgroundTextSectionProps {
    withTopPadding?: boolean;
    className?: string;
}

export default function BackgroundTextSection({ withTopPadding, className }: BackgroundTextSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'quote');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${className} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={content}>
                    <div className={image}>
                        &nbsp;
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
}
